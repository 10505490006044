// Generated by Framer (4175fb7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {eEZGnfswo: {hover: true}, WM0yTCkne: {hover: true}};

const cycleOrder = ["eEZGnfswo", "WM0yTCkne"];

const serializationHash = "framer-ZvJaj"

const variantClassNames = {eEZGnfswo: "framer-v-9jrmwf", WM0yTCkne: "framer-v-igx568"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation = {opacity: 1, rotate: 0, scale: 1, transition: transition2}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Logo Full": "WM0yTCkne", Logo: "eEZGnfswo"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "eEZGnfswo"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "eEZGnfswo", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "VJAzs2Sv4"}} openInNewTab={false}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-9jrmwf", className, classNames)} framer-1oddkp4`} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"eEZGnfswo"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"eEZGnfswo-hover": {"data-framer-name": undefined}, "WM0yTCkne-hover": {"data-framer-name": undefined}, WM0yTCkne: {"data-framer-name": "Logo Full"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 768, intrinsicWidth: 1024, pixelHeight: 768, pixelWidth: 1024, sizes: `calc(${componentViewport?.width || "100vw"} + 1px)`, src: "https://framerusercontent.com/images/EFqi4vb6oPpkjmdZcSpWZwi48c.png", srcSet: "https://framerusercontent.com/images/EFqi4vb6oPpkjmdZcSpWZwi48c.png?scale-down-to=512 512w,https://framerusercontent.com/images/EFqi4vb6oPpkjmdZcSpWZwi48c.png 1024w"}} className={"framer-8jp0hz"} data-highlight layoutDependency={layoutDependency} layoutId={"o1dyXGR9t"} style={{filter: "none", WebkitFilter: "none"}} variants={{"eEZGnfswo-hover": {filter: "drop-shadow(-2px 2px 9px rgba(241, 56, 255, 0.25))", WebkitFilter: "drop-shadow(-2px 2px 9px rgba(241, 56, 255, 0.25))"}}} whileHover={animation}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZvJaj.framer-1oddkp4, .framer-ZvJaj .framer-1oddkp4 { display: block; }", ".framer-ZvJaj.framer-9jrmwf { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-ZvJaj .framer-8jp0hz { flex: none; height: 36px; overflow: hidden; position: relative; width: 98px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ZvJaj.framer-9jrmwf { gap: 0px; } .framer-ZvJaj.framer-9jrmwf > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-ZvJaj.framer-9jrmwf > :first-child { margin-left: 0px; } .framer-ZvJaj.framer-9jrmwf > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 98
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"WM0yTCkne":{"layout":["auto","auto"]},"vmUqmg4qC":{"layout":["auto","auto"]},"joJVt1EHi":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDtRGLx0ml: React.ComponentType<Props> = withCSS(Component, css, "framer-ZvJaj") as typeof Component;
export default FramerDtRGLx0ml;

FramerDtRGLx0ml.displayName = "Logo";

FramerDtRGLx0ml.defaultProps = {height: 36, width: 98};

addPropertyControls(FramerDtRGLx0ml, {variant: {options: ["eEZGnfswo", "WM0yTCkne"], optionTitles: ["Logo", "Logo Full"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerDtRGLx0ml, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})